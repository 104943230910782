/**
 * @classdesc FavcyAnalytics class is used to track user activity.
 * @constructor
 */
import {HttpHandler} from "../common/http";
import {getLocalData, removeLocalData, setLocalData} from "../common/cache";
import {parseJwt, parseURLParams} from "../common/common";
import {FavcySDKEvent} from "./FavcySDKEvent";

const configuration = require('../../path.json');


/** @TODO: Change this and write in ES6 syntax **/
export function FavcyClient(appId) {

    let mAppId = appId;
    let obj = this;
    const url = configuration.baseURL + "/api/2.0/auth-token";
    let parentID = 0;
    let startPoint = 0;
    let mAuthToken = 0;
    let mEventManager = null;

    /**
     *
     * @param eventManager {FavcyEventManager}
     * @return {Promise}
     */
    this.initialize = function (eventManager) {

        let data = parseURLParams();
        this.setStartPoint(data["start_point"]);
        mEventManager = eventManager;

        return new Promise(function (resolve, reject) {

            if (parseURLParams()['auth_token']) {
                mAuthToken = parseURLParams()['auth_token'];
                obj.setAuthToken(mAuthToken);
                resolve(mAuthToken);
                monitorTokenChange();
            } else {
                getLocalData(mAppId + "_authToken", null).then(
                    response => {
                        mAuthToken = response;
                        if (!mAuthToken || isTokenExpired(mAuthToken)) {
                            generateAuthTokenFromServer(resolve, reject);
                        } else {
                            resolve(mAuthToken);
                            monitorTokenChange();
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            }

        });
    };

    function isTokenExpired(token) {
        /* Expiry is stored in seconds, while now returns in ms */
        let expiry = parseJwt(token)['expiry'];
        return ((expiry * 1000 )< Date.now());
    }

    function generateAuthTokenFromServer(resolve, reject) {
        obj.generateAuthToken().then(
            response => {
                obj.setAuthToken(response.data.data.token);
                resolve(mAuthToken);
                monitorTokenChange();
            },
            error => {
                reject(error);
            }
        )
    }

    this.generateAuthToken = function (loggedOutStatus) {
        const httpHandler = new HttpHandler();
        httpHandler.addHeader('appid', mAppId);
        httpHandler.addHeader('app-id', mAppId);
        httpHandler.addHeader('Accept', 'application/json');
        if (loggedOutStatus !== undefined && loggedOutStatus !== null && loggedOutStatus === true) {
            httpHandler.addHeader('Auth-Token', mAuthToken);
        }
        return httpHandler.httpPost(url, "");
    };

    this.setParentID = function (parentId) {
        parentID = parentId;
    };

    this.setStartPoint = function (startPt) {
        startPoint = startPt;
    };

    this.getParentID = function () {
        return parentID;
    };

    this.getStartPoint = function () {
        return startPoint;
    };

    this.setAuthToken = function (token) {
        mAuthToken = token;
        setLocalData(mAppId + "_authToken", token);
        monitorTokenChange();
    };


    this.getAuthToken = function () {
        return mAuthToken;
    };

    this.getDataFromToken = function (parameterName) {
        let data = parseJwt(mAuthToken);
        return data[parameterName];
    };


    this.resetAuthToken = function () {

        removeLocalData(mAppId + "_authToken", mAuthToken);
        mAuthToken = 0;
    };

    function monitorTokenChange() {
        setInterval(function () {
            getLocalData(mAppId + "_authToken", mAuthToken).then(
                token => {
                    if (token && token !== mAuthToken) {
                        obj.setAuthToken(token);
                        if (mEventManager) {
                            mEventManager.fire(FavcySDKEvent.eventNames.onTokenChange, token);
                        }
                    }
                });

        }, 5000);

    }
}
