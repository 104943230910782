/*
 * Created by favcy-pc on 10-06-2017.
 */
import * as FileSaver from "file-saver";

let base64Encoder = require('base-64');
let utf8 = require('utf8');

const defaultLoginConfig = require("resources/json/loginconfig");


// Polyfill to support entries in case browser does not support it
(function(){
    if (!Object.entries)
        Object.entries = function( obj ){
            let ownProps = Object.keys( obj ),
                i = ownProps.length,
                resArray = new Array(i); // preallocate the Array
            while (i--)
                resArray[i] = [ownProps[i], obj[ownProps[i]]];

            return resArray;
        };
})();


export function isMobile() {
    return window.innerWidth <= 767;
}

export function getValidUrl(url = "") {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if(/^(:\/\/)/.test(newUrl)){
        return `http${newUrl}`;
    }
    if(!/^(f|ht)tps?:\/\//i.test(newUrl)){
        return `http://${newUrl}`;
    }

    return newUrl;
};

export function isSafari () {
    return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
}


export function isIOS() {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
}

export function isFacebookAppBrowser() {
    let ua = navigator.userAgent || navigator.vendor || window.opera;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1) || (ua.indexOf("Instagram") > -1);
}

export function parseURLParams() {
    let urlParams = [];
    if (window !== undefined && window.location !== undefined) {
        window.location.search
            .replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str, key, value) {
                    urlParams[key] = value;
                }
            );
    }
    return urlParams;
}

export function SDKCommon() {
    // Promise.all() for objects
    Object.defineProperty(Promise, 'allKeys', {
        configurable: true,
        writable: true,
        value: async function allKeys(object) {
            const resolved = {};
            const promises = Object
                .entries(object)
                .map(async ([key, promise]) =>
                    resolved[key] = await promise
                );

            await Promise.all(promises);

            return resolved
        }
    });
}

export function objExtend(obj1, obj2) {
    let rVal = obj1;
    let keys = Object.keys(obj2);
    for (let i = 0; i < keys.length; i += 1) {
        let val = obj2[keys[i]];
        rVal[keys[i]] = ['string', 'number', 'array', 'boolean', 'undefined'].indexOf(typeof val) === -1 ? objExtend(rVal[keys[i]] || {}, val) : val;
    }
    return rVal;
}

export function addTrackingParams(url) {
    let symbol = "?";
    if (url.indexOf("?") > -1)
        symbol = "&";

    let parentID = 0;
    if (parentID === undefined || parentID === null || parentID === "")
        parentID = 0;
    if (url.indexOf("parent_id") < 0) {
        url += symbol + "parent_id=" + parentID;
        symbol = "&";
    }

    let startPoint = "something";
    if (startPoint === undefined || startPoint === null || startPoint === "")
        startPoint = 0;
    url += symbol + "start_point=" + startPoint;
    symbol = "&";

    /*if (url.indexOf("auth_token") < 0) {
        let authToken = FavcySDK.getInstance().getClient().getAuthToken();
        if (authToken === undefined || authToken === null || authToken === "")
            authToken = 0;
        url += symbol + "auth_token=" + authToken;
    }*/
    return url;
}

export function parseJwt(token) {

    if (typeof token === "string") {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');

        let bytes = utf8.encode(base64);
        let decoded = base64Encoder.decode(bytes);
        return JSON.parse(decoded);
    }
    else {
        return new Error("Type is not string");
    }

}


export function appendArguments(url, args) {
    let symbol = "?";
    if (url.indexOf("?") > -1)
        symbol = "&";
    for (let key in args) {
        if (args.hasOwnProperty(key)) {
            let val = args[key];
            if (key === "attribute") {
                val = window.btoa(JSON.stringify(args[key]));
            }
            url += symbol + key + "=" + val;
            symbol = "&";
        }
    }
    return url;
}

export function downloadFile(url) {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
        if (this.status === 200) {
            let filename = "";
            let disposition = xhr.getResponseHeader('Content-Disposition');
            if (disposition && disposition.indexOf('attachment') !== -1) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(disposition);
                if (matches !== null && matches[1]) filename = matches[1].replace(/['"]/g, '');
            }
            let type = xhr.getResponseHeader('Content-Type');

            let blob = new Blob([this.response], {type: type});
            FileSaver.saveAs(blob, filename);
        }

    };

    xhr.setRequestHeader('Content-type', 'application/octet-stream');
    xhr.send();
}


export function saveFileToBrowser(blob, filename) {

    if (typeof window.navigator.saveBlob !== 'undefined' || typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.saveBlob(blob, filename);
    }
    else {
        let blobURL = window.URL.createObjectURL(blob);
        let tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);
        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }

        window.open(filename);
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }
}


export function objectToFormData(obj, rootName, ignoreList) {
    let formData = new FormData();

    function appendFormData(data, root) {
        if (!ignore(root)) {
            root = root || '';
            if (data instanceof File) {
                formData.append(root, data);
            } else if (Array.isArray(data)) {
                for (let i = 0; i < data.length; i++) {
                    appendFormData(data[i], root + '[' + i + ']');
                }
            } else if (typeof data === 'object' && data) {
                for (let key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            appendFormData(data[key], key);
                        } else {
                            appendFormData(data[key], root + '[' + key + ']');
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data);
                }
            }
        }
    }

    function ignore(root) {
        return Array.isArray(ignoreList)
            && ignoreList.some(function (x) {
                return x === root;
            });
    }

    appendFormData(obj, rootName);

    return formData;
}


export function getDefaultLoginSettings(settings, appSettings, theme) {

    let defaultLoginSettings = JSON.parse(JSON.stringify(defaultLoginConfig.config.defaultSettings));
    let defaultConfig = {
        "primaryColor": theme.palette.accent1Color,
        "secondaryColor": theme.palette.textColor
    };

    let config = appSettings['login_settings'];
    config = ((config === undefined || config === null) ? {} : config);
    let defaultSettings = objExtend(defaultLoginSettings, defaultConfig);
    defaultSettings = objExtend(defaultSettings, config);

    settings = (settings === undefined || settings === null) ? {} : settings;
    settings = objExtend(defaultSettings, settings);


    if (settings.refCode === undefined) {
        settings.refCode = parseURLParams().refCode;
    }
    return settings;

};

export function getDisplayName(userData={}) {

    if(userData.display_name){
        return userData.display_name;
    }else if(userData.first_name && userData.last_name){
        return userData.first_name + " " + userData.last_name;
    }else if(userData.first_name){
        return userData.first_name;
    }else if(userData.last_name){
        return userData.last_name;
    }else if(userData.mobile){
        return userData.mobile.toString().replace(/.(?=.{4})/g, 'X');
    }else{
        return "";
    }
}

export function replaceTeamName(str){
    let teamMapping = [
        {
            'name': "Mumbai Indians",
            'replacement': "Mumbai"
        }, {
            'name': "Royal Challengers Bangalore",
            'replacement': "Bangalore"
        },
        {
            'name': 'Delhi Capitals',
            'replacement': "Delhi"

        },
        {
            'name': 'Chennai Super Kings',
            'replacement': "Chennai"

        },
        {
            'name': 'Kolkata Knight Riders',
            'replacement': "Kolkata"

        },
        {
            'name': 'Kings XI Punjab',
            'replacement': "Punjab"

        },
        {
            'name': 'Sunrisers Hyderabad',
            'replacement': "Hyderabad"

        },
        {
            'name': 'Rajasthan Royals',
            'replacement': "Rajasthan"

        }
    ];

    teamMapping.forEach((obj)=> {
        if(str.includes(obj.name)){
            str = str.replace(new RegExp(obj.name, 'g'), obj.replacement);
        }
    });

    return str;

}

export  function getCommaSeparatedPrice(cost) {
    if(cost !== undefined && cost !== null){
        let decimalSeparatedValues = cost.toString().split(".");
        let integerValue = decimalSeparatedValues[0];
        let decimalValue = decimalSeparatedValues[1];

        //step 2 as we want to apply comma after every 2
        let step = 2;
        let finalValue = integerValue.substring(integerValue.length - 3, integerValue.length);
        for(let i = 3; i < integerValue.length; i += step) {
            finalValue = integerValue.substring(integerValue.length - i - step, integerValue.length - i) + "," + finalValue;
        }
        return finalValue + (decimalValue ? "." + decimalValue : "");
    }
    return;
}

export function setCookie(name,value,days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export function readCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export function deleteCookie(name) {
    document.cookie = name+'=; Max-Age=-99999999;';
}

export function capitalize(string){
        return string.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
}

export function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
 }

export function gaEventTrigger(eventCategory,eventAction,eventLabel,eventValue){
 if(eventCategory === undefined){
     return "Event Category is required";
 }
 if (eventAction === undefined) {
     return "Event Action is required";
 }
 
 if ("ga" in window) {
     let tracker = window.ga.getAll()[0];
     if (tracker) tracker.send("event", eventCategory, eventAction, eventLabel, eventValue);
 }   
}

export function  getRandomItemsFromArray(arr, n) {
    let result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        let x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}

/**
 * 
 * @param {String} placeId Google Map Place Id this url works in Android , IOS , Web
 */
export function mapsURLFromPlaceId(placeId){
    return `https://www.google.com/maps/search/?api=1&query=%22%22&query_place_id=${placeId}`;
}

export function hexToRgbA(hex,alphaValue=.1){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+alphaValue+')';
    }
    return "";
}

export async function urlToFile(mediaObject=[]){
    let media=[];
    try{ 
        if(mediaObject && mediaObject.length){
            /**
             * allFetchPromise ---> Promise Containing all Fetch Requests
             * Url to Data ----> Mainly for File Name Save 
             * blobArray ---> will contain all Blobs
             */
            let allFetchRequestPromiseArray=[];
            let blobArray=[];
            let urlToData={}
            mediaObject.forEach(data=>{
                if(data.full_media_url){
                    urlToData[data.full_media_url] = data;
                    allFetchRequestPromiseArray.push(
                        fetch(data.full_media_url)
                        .then(response=>{
                            if(response){
                                return {
                                    status:true , 
                                    response: response.blob().then(blob=>({blob:blob,id: data.id, actualURL: data.full_media_url,status:true, type:data.type})).catch(err=>({status:false})) , 
                                    id: data.id, url: data.full_media_url,
                                }
                            }else{
                                return {
                                    status:false
                                }
                            }
                        })
                        .catch(err=>({status:false,error:err, id: data.id,url: data.full_media_url}))
                    )
                };
            })
            if(allFetchRequestPromiseArray.length){
                let allResponses = await Promise.all(allFetchRequestPromiseArray);
                // checking all responses of Files
                allResponses.forEach(data=>{
                    if(data && data.status){
                        blobArray.push(data.response);
                    }
                })
                // Checking All Blobs and  its conversion status 
                /**
                 * if status is false then either unable to find file or else its deleted
                 */
                let allBlobs = await Promise.all(blobArray);
                allBlobs.forEach(blob=>{
                    if(blob.status){
                        let obj={};
                        if(blob.type){
                            obj.type= blob.type;
                        }
                        let data =  urlToData[blob.actualURL];
                        let file = new File([blob.blob],data.media_name,obj);
                        file.preview = URL.createObjectURL(blob.blob)
                        media.push(file);
                    }
                })
            }
        }
        return media;
    }
    catch(err){
        return []
    }
}

export function getYoutubeVideoID(url="") {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11) ? match[2] : null;
}

export function extractError(err , defaultMessage){
    if (
        err &&
        ((err.response && err.response.data.error.message) ||
            err.message)
    ) {
            return err.response ? err.response.data.error.message : err.message
    }else if(err && err.error && err.error.message){
        return err.error.message;
    } 
    else {
        return err || defaultMessage ;
    }
}

export function getImageResizeURL(mediaObj={},size){
    let { media_domain , full_media_path , full_media_url } = mediaObj || {};
    if(!size){
        return full_media_url
    }
    let url = full_media_url;
    if(full_media_path && media_domain){
        url = media_domain +`/resize/${size}/` +full_media_path ;
    }
    return url;
}

export function getFormDataSize(formData){
    return Array.from(formData.entries(), ([key, prop]) =>{
        return {
            key: key,
            size: typeof prop === "string" ? prop.length : prop.size
        }
    });
}
