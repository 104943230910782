import React from "react";
import Loadable from "react-loadable";

export default function ModuleLoadable(options) {
    return Loadable(Object.assign({
        loading: Loading,
        delay: 200,
        timeout: 10000,
    }, options));
}

function Loading(props) {
    let element = null;

    if (props.error) {
        element = <div>Error! <button onClick={props.retry}>Retry</button></div>;
    } else if (props.timedOut) {
        element = <div>Taking a long time... <button onClick={props.retry}>Retry</button></div>;
    } else if (props.pastDelay) {
        element = <div>Loading...</div>;
    }

    return element;
}
