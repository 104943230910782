import {BaseVideoTracker} from "./BaseVideoTracker";
export function FavcyYoutubeTracker(userObj) {

    this.initialize = function () {
        const instance = this;
        return new Promise(function (resolve, reject) {
            try {
                let tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";
                let firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
                let objectArray = [];
                let youTubeArray = [];
                window.onYouTubeIframeAPIReady = function () {
                    setInterval(function () {
                        let iFrames = document.getElementsByTagName("iframe");
                        for (let i = 0; i < iFrames.length; i++) {
                            let element = iFrames[i];

                            if (element.src.indexOf("youtube.com") > -1) {
                                if (element.src.indexOf("enablejsapi=1") === -1) {
                                    if (element.src.indexOf("?") > -1) {
                                        element.src += "&enablejsapi=1";
                                    }
                                    else {
                                        element.src += "?enablejsapi=1";
                                    }
                                }
                                if (youTubeArray.indexOf(element.src) === -1) {
                                    youTubeArray.push(element.src);
                                    objectArray.push = new YouTubeTracker(element, userObj);
                                }
                            }
                        }
                    }, 10000);
                };
                resolve(instance);
            } catch (error) {
                reject(error);
            }
        })
    };
}

class YouTubeTracker extends BaseVideoTracker {

    constructor(element, userObj) {
        super();
        this.onPlayerStateChange = this.onPlayerStateChange.bind(this);
        let player = new window.YT.Player(element, {
            events: {
                'onReady': this.onInit,
                'onStateChange': this.onPlayerStateChange
            }
        });
        this.init(player, "YouTube", userObj);
    }

    onPlayerStateChange(state) {
        switch (state.data) {
            case 0:
                this.onComplete();
                break;
            case 2:
                this.onPause();
                break;
            default:
                break;
        }
    }
}
