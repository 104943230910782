import React from "react";
import EmbeddedWidget from "./EmbeddedWidget";

class DynamicLoginWrapper extends React.Component {
    constructor(props) {
        super(props);
        const instance = this;
        instance.state = {
            'loginComponent' : null
        }

    }

    setLoginComponent = (e) => {
        this.setState(() => {
            return {
                'loginComponent': e,
            }
        },() => {
            this.state.loginComponent.loginStart();
        });
    };

    render(){
        return (
            <EmbeddedWidget type={"login"} id={0} {...this.props}  reference={(e) => this.setLoginComponent(e)} />
        )
    }
}

export default DynamicLoginWrapper;
