import {FavcySDKEvent} from "./FavcySDKEvent";
import {FavcyClient} from "./FavcyClient";
import RestEndPoint from "sdk-js/main/RestEndPoint";
import {appendArguments, objExtend} from "../common/common";
import {HttpHandler} from "../common/http";
import {toast} from "react-toastify";
import {WidgetHelper} from "../../components/widgets/WidgetHelper";



/**
 * @classdesc Favcy Application class represents an object for the
 * application setting and provides information related to the application
 * and associated brand information.
 *
 * In case you are using the {@link FavcySDK} class, you need NOT explicitly create
 * the object as you can fetch the {@link FavcyApplication} object from the SDK
 * itself {@link FavcySDK#getApplication}.
 *
 * @class
 * @param {String} appId - Favcy Application ID
 * @constructor
 */
class FavcyApplication {

    constructor(appId) {
        this.mEventManager = null;
        this.mAppId = appId;
        this.mClient = new FavcyClient(this.mAppId);
        this.mRestClient = null;
        this.mAppData = null;
    }

    /**
     *
     * The function is used to initialize the FavcyApplication Object. It is important to initialize
     * the object, before using it. The function fetches, all the settings related to the application
     * and also triggers the tracking (page visit, video and social tracking), if configured, for the application.
     * @function
     * @param {FavcyEventManager} [eventManager] - Optional. Instance of the Favcy
     * Event Manager class. If not provided, the application would not be able to send
     * any events.
     * @return {Promise} - If the promise is successful, the response is an in instance to the current object (this).
     */
    init = (eventManager) => {
        const instance = this;
        if (undefined !== eventManager && typeof eventManager === 'object') {
            instance.mEventManager = eventManager;
        }

        return instance.mClient.initialize(instance.mEventManager).then(
            response => import('resources/json/platform')
        ).then(
            config => {
                instance.mRestClient = new RestEndPoint('apps', 'me', config['apps'], {'Auth-Token': instance.mClient.getAuthToken()});
                instance.mRestClient.updateOptions('', {with: {brand: {with:{owner_profile:{}}}}});
                return instance.mRestClient.getData('')
            }
        ).then(
            data => {
                instance.mAppData = data;
                instance.raiseEvent(FavcySDKEvent.eventNames.onApplicationInit, instance);
                return instance;
            }
        );
    };

    /**
     * The function is used to fetch an app attribute
     * @param attribute
     * @param optionalId
     * @param options
     * @return Promise which on resolution provides the attribute requested.
     */
    getAppData = (attribute, optionalId, options) => {
        const instance = this;
        if (options && options !== undefined) {
            instance.mRestClient.updateOptions(options);
        }
        return instance.mRestClient.getData(attribute, optionalId);
    };

    /**
    * This function returns an Object of Widget Helper
    *
    **/
    getWidgetHelper = (favcyContext, enableOnBoarding) => {
        return new WidgetHelper(favcyContext, enableOnBoarding);
    };

    /**
     *  The function returns an object with the application settings.
     * @return Object
     */
    getData = () => {
        return this.mAppData;
    };

    raiseEvent(eventName, eventData) {
        if (this.mEventManager) {
            this.mEventManager.fire(eventName, eventData);
        }
    }

    getClient = () => {
        return this.mClient;
    };


    /** TODO: All functions below this are to be made obsolete **/
    getWidgetData = (widgetType, widgetID, options) => {

        const instance = this;
        return new Promise(function (resolve, reject) {

            const configuration = require("path.json");
            const mUrlMap = require('resources/json/widgetUrlMap.json');
            let httpHandler = new HttpHandler();
            httpHandler.addHeader('appid', instance.mAppId);
            httpHandler.addHeader('app-id', instance.mAppId);
            httpHandler.addHeader('Accept', 'application/json');
            httpHandler.addHeader('Auth-Token', instance.mClient.getAuthToken());

            let url = "";
            if (mUrlMap[widgetType] === undefined) {
                url = configuration.widgetBaseURL + "/widgets/api/1.0/" + widgetType;
            } else {
                url = configuration.widgetBaseURL + mUrlMap[widgetType].apiPrefix;
                if (!mUrlMap[widgetType].apiPrefix) {
                    /* Local Widget with no data from Backend
                    * @TODO: Function way too complex. Need to simplify */
                    resolve({dummyDataKey: "dummyDataValue"});
                    return;
                }
            }
            if (widgetID !== "" && widgetID !== 0) {
                url += "/" + widgetID;
            }
            if (options !== undefined && options !== null && options.data === true) {
                if (widgetType === "membershipLevel") {
                    url += "/memberships/me";
                }
                else {
                    url += "/data/me";
                }
            }
            if (options !== undefined && options !== null && options.newFormat === true) {
                url += "/" + mUrlMap[widgetType].name;
            }
            if (options !== undefined && options !== null && options.action !== undefined) {
                url += "/" + options.action;
            }
            if (options !== undefined && options !== null && options.arguments !== undefined && options.arguments !== null) {
                let args = options.arguments;
                url = appendArguments(url, args);
            }
            return httpHandler.httpGet(url).then(
                response => {
                    resolve(response.data.data);
                },
                error => {
                    reject(error);
                }
            );
        });
    };

    /**
     * Function which create toast notification.
     * @param message Text message to show in a toast
     */
    showNotification = (message , obj) => {
        if(toast){
            if(!obj)  {
              obj =  {
                    position: toast.POSITION.TOP_RIGHT,
                    className: "notification",
                    autoClose: 2000
                }
            };
            toast(message,obj );
        }
    };

    callApi = (widgetType, widgetID, action, data, httpMethod, config) => {
        const instance = this;
        let defaultConfig = {
            'appid': instance.mAppId,
            'app-id': instance.mAppId,
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Auth-Token': instance.mClient.getAuthToken()
        };

        if (undefined !== config)
            defaultConfig = objExtend(defaultConfig, config);

        return new Promise(function (resolve, reject) {

            let httpHandler = new HttpHandler();
            httpHandler.addHeaders(defaultConfig);
            const configuration = require("path.json");
            const mUrlMap = require('resources/json/widgetUrlMap.json');

            let url = "";
            if (mUrlMap[widgetType] === undefined) {
                url = configuration.widgetBaseURL + "/widgets/api/1.0/" + widgetType;
            } else {
                url = configuration.widgetBaseURL + mUrlMap[widgetType].apiPrefix;
            }
            if (widgetID !== "") {
                url += "/" + widgetID + "/" + action;
            }

            return httpHandler.httpPost(url, data, null).then(
                response => {
                    resolve(response.data);
                },
                error => {
                    reject(error);
                }
            );
        });
    };

    getBlobData = (blobID) => {
        return this.getWidgetData('blob', blobID);
    };

    getWidgetPreviewUrl = (widgetType, widgetId) => {
        const configuration = require("path.json");
        const mUrlMap = require('resources/json/widgetUrlMap.json');
        if (mUrlMap[widgetType] === undefined) {
            return configuration.widgetBaseURL + "/widgets/" + widgetType + '/?id=' + widgetId + "&auth_token=" + this.mClient.getAuthToken();
        } else {
            return configuration.widgetBaseURL + mUrlMap[widgetType].webPrefix + '/?id=' + widgetId + "&auth_token=" + this.mClient.getAuthToken();
        }
    };
}

export default FavcyApplication;

