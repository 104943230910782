import RestEndPointWrapper from "sdk-js/main/RestEndPointWrapper";
import * as React from "react";
import ModuleLoadable from "main/ModuleLoadable";
import {FavcySDKEvent} from "sdk-js/main/FavcySDKEvent";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import {MuiThemeProvider} from "material-ui";
import {withFavcyContext} from "./FavcyContext";


class LocalEmbedderNew extends React.Component {
    constructor(props) {
        super(props);
        const instance = this;
        instance.state = {
            data: null,
            restEndPoint: null
        };

        this.isEndpointPresent = props.options.isEndpointPresent !== undefined ? props.options.isEndpointPresent : true;
        instance.listenLoginEvent(props);
        instance.localWidget = null;
    }

    componentWillMount(){

        let instance = this;

        if(instance.isEndpointPresent){
            instance.init(instance.props);
        }else {
            instance.renderWidget();
            instance.setState({data: null,renderNoResult : true});
        }

    }

    listenLoginEvent = (props) => {
        let instance = this;
        let sdkInstance = instance.props.favcyContext.sdkInstance;

        sdkInstance.subscribeEvents(FavcySDKEvent.eventNames.onUserLogin, function (eventName, token) {
            if(instance.props.options.loginReRender){
                if(this.isEndpointPresent){
                    instance.init(props);
                    instance.renderedAfterLogin = true;
                }else {
                    instance.renderWidget();
                    instance.setState({data: null,renderNoResult : true});
                }

            }
        }.bind(this));

        sdkInstance.subscribeEvents(FavcySDKEvent.eventNames.onUserLogout, function (eventName, token) {
            if(instance.props.options.loginReRender && instance.isEndpointPresent){
                instance.init(props);
                instance.renderedAfterLogin = false;
            }
        }.bind(this));

    };

    init(props) {
        const instance = this;
        return import("resources/json/widgets").then(
            config => {
                const authToken = instance.props.favcyContext.sdkInstance.getClient().getAuthToken();
                const endPointName = props.type + "-campaigns";
                const restEndPoint = new RestEndPointWrapper(endPointName, props.id, config[endPointName], {'Auth-Token': authToken});
                instance.setState({restEndPoint: restEndPoint});
                if (props.options !== undefined && props.options && props.options.arguments !== undefined) {
                    let args = this.getArgs(props.options.arguments);
                    restEndPoint.updateOptions("", args);
                }
                return restEndPoint.getDataWithMeta("");
            }
        ).then(
            response => {
                if (response && response.meta && response.meta.token){
                    let currentRestEndPoint = instance.state.restEndPoint;
                    let httpHeaders = currentRestEndPoint.getHttpHeaders();
                        httpHeaders['Widget-Token'] = response.meta.token;
                        currentRestEndPoint.setHttpHeaders(httpHeaders);
                        instance.setState({restEndPoint: currentRestEndPoint, meta: response.meta});
                }

                instance.renderWidget();
                if ((response.data && response.data.constructor === Object && Object.keys(response.data).length !== 0) ||
                    (response.data && response.data.constructor === Array && response.data.length !== 0)
                ) {
                    instance.setState({data: response.data});
                } else if(instance.props.options.renderNoResult){
                    instance.setState({data: null,renderNoResult : true});
                }else {
                    throw new Error("No Data found :(" + props.type + "[" + props.id + "]");
                }
            }
        ).catch(error => console.log(error.stack));
    }

    renderWidget() {
        const instance = this;
        if(!instance.localWidget) {
            instance.localWidget = ModuleLoadable({
                loader: () => instance.props.widget
            });
        }
    };

     getArgs = (args) => {
        let instance = this;
        let rVal = JSON.stringify(args);
        rVal = rVal.replace(new RegExp("\\bme\\b"), instance.props.favcyContext.sdkInstance.getUser().getData().id);
        rVal = JSON.parse(rVal);
         return rVal;
     };

    render() {
        const instance = this;

        return (
            <React.Fragment>
                {instance.state.data || instance.state.renderNoResult ?
                <MuiThemeProvider muiTheme={getMuiTheme(instance.props.favcyContext.theme)}>
                    <instance.localWidget
                        {...instance.state}
                        options={instance.props.options}
                        reference={instance.props.reference}
                        {...instance.props.favcyContext}
                        renderedAfterLogin = {instance.renderedAfterLogin}
                        type = {instance.props.type}
                        id= {instance.props.id}
                    />
                </MuiThemeProvider> : null}
            </React.Fragment>
        );
    }
}

export default withFavcyContext(LocalEmbedderNew);
