import RestEndPoint from "./RestEndPoint";

class RestEndPointWrapper extends RestEndPoint {


    postDataWithLogin = (favcyContext, attribute, formData, editId , config,version = null) => {

        const instance = this;
        if (favcyContext.user.isLoggedIn()) {

            let httpHeaders = instance.getHttpHeaders();

            if (httpHeaders.hasOwnProperty('Auth-Token')) {
                httpHeaders['Auth-Token'] = favcyContext.sdkInstance.getClient().getAuthToken();
                instance.setHttpHeaders(httpHeaders);
            }

            return instance.postData(attribute,formData, editId , config,version);

        } else {
            return new Promise(function (resolve, reject) {
                // False is for onboarding. Can be changed
                favcyContext.app.getWidgetHelper(favcyContext, false).triggerUserLogin(
                    token => {
                        if (token) {
                            let httpHeaders = instance.getHttpHeaders();

                            if (httpHeaders.hasOwnProperty('Auth-Token')) {
                                httpHeaders['Auth-Token'] = token;
                                instance.setHttpHeaders(httpHeaders);
                            }

                            instance.postData(attribute, formData, editId ,config)
                                .then(
                                    response => {
                                        resolve(response)
                                    },
                                    error => {
                                        reject(error.message)
                                    }
                                );
                        }
                        else {
                            reject("Login Failed.Please Try Again");
                        }
                    });

            });
        }


    };

    getDataWithLogin = (favcyContext, attribute, optionalId) => {
        const instance = this;
        if (favcyContext.user.isLoggedIn()) {
            let childIndex = instance.config.children.indexOf(attribute);
            if(childIndex > 0 && instance.currentOptions && instance.currentOptions[childIndex]){
                let options = instance.currentOptions[childIndex];
                instance.updateOptions(attribute, instance.replaceMe(favcyContext, options));
            }
            return instance.getData(attribute, optionalId);
        }else{
            return new Promise(function (resolve, reject) {
                // False is for onboarding. Can be changed
                favcyContext.app.getWidgetHelper(favcyContext, false).triggerUserLogin(
                    token => {
                        if (token) {
                            let httpHeaders = instance.getHttpHeaders();

                            if (httpHeaders.hasOwnProperty('Auth-Token')) {
                                httpHeaders['Auth-Token'] = token;
                                instance.setHttpHeaders(httpHeaders);
                            }

                            let childIndex = instance.config.children.indexOf(attribute);
                            if(childIndex > 0 && instance.currentOptions && instance.currentOptions[childIndex]){
                                let options = instance.currentOptions[childIndex];
                                instance.updateOptions(attribute, instance.replaceMe(favcyContext, options));
                            }


                            instance.getData(attribute,optionalId)
                                .then(
                                    response => {
                                        resolve(response)
                                    },
                                    error => {
                                        reject(error.message)
                                    }
                                );
                        }
                        else {
                            reject("Login Failed.Please Try Again");
                        }
                    });

            });
        }
    };

    replaceMe(favcyContext, options){
        let rVal = JSON.stringify(options);
        rVal = rVal.replace(new RegExp("\\bme\\b"), favcyContext.sdkInstance.getUser().getData().id);
        rVal = JSON.parse(rVal);

        return rVal;
    }

}

export default RestEndPointWrapper;
