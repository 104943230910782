/**
 * Created by rajachhabra on 23/02/18.
 */



export class BaseVideoTracker {

    init(playerInstance, type, userObj) {
        this.mPlayerInstance = playerInstance;
        this.mType = type;
        this.mTotalPlayTime = 0;
        this.mLastPlayTime = Date.now();
        this.userObj = userObj;
    }

    onInit() {

    }

    onPlay() {
        this.mLastPlayTime = Date.now();
    }

    onPause() {
        this.mTotalPlayTime += Date.now() - this.mLastPlayTime;
        this.mLastPlayTime = Date.now();
    }

    getDuration(type) {
        let returnVal = "";
        switch (type) {
            case "Facebook":
                returnVal = this.mPlayerInstance.getDuration();
                break;
            case "YouTube":
            default:
                returnVal = this.mPlayerInstance.getDuration();
                break;
        }
        return returnVal;
    }

    getUrl(type) {
        let returnVal = "";
        switch (type) {
            case "Facebook":
                returnVal = this.mPlayerInstance.id;
                if (returnVal === undefined) {
                    returnVal = Math.random(1000).toFixed(0);
                }
                break;

            case "YouTube":
            default:
                returnVal = this.mPlayerInstance.getVideoUrl();
                break;
        }
        return returnVal;
    }

    onComplete() {

        this.mTotalPlayTime += Date.now() - this.mLastPlayTime;
        let videoData = {
            url: this.getUrl(this.mType),
            played_time: this.mTotalPlayTime / 1000,
            video_length: this.getDuration(this.mType),
            type: this.mType,

        };
        this.userObj.postData("action-transactions", {action_code: "youtube_video", action_data: videoData}).catch(
            error => console.log("ERROR: Failed to Pass Point for page Visit")
        );
    }
}
