import React from "react";
import FavcyApp from "../../main/FavcyApp";
import {FavcySDKEvent} from "../../sdk-js/main/FavcySDKEvent";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import {MuiThemeProvider} from "material-ui";
import ModuleLoadable from "../../main/ModuleLoadable";
import RestEndPoint from "sdk-js/main/RestEndPoint";
import {withFavcyContext} from "./FavcyContext";


class LocalEmbedder extends React.Component {
    constructor(props) {
        super(props);
        this.checkPost = this.checkPost.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.mWidgetType = null;
        this.mWidgetID = null;
        this.mAction = null;
        this.mData = null;
        this.mMethod = null;
        this.mConfig = null;
        this.mResolve = null;
        this.mReject = null;
        this.state = {
            data: null,
            component: null,
            showLoader: false,
            dataExist: false,
            response: null,
            dataList: null
        };

        this.stateModifiers = {
            checkPost: this.checkPost,
            notify: this.notify,
            notifier: this.notify
        };

        this.LocalWidget = null;
    }

    notify = (message) => {
        if (this.props.showNotification) {
            this.props.showNotification(message);
        } else {
            this.props.favcyContext.app.showNotification(message);
        }
    };

    requestData = () => {
        const instance = this;
        const authToken = instance.props.favcyContext.sdkInstance.getClient().getAuthToken();
        import("resources/json/widgets")
            .then(
                config => {
                    instance.config = config;
                    const restEndPoint = new RestEndPoint("setting-campaigns", 0, config['setting-campaigns'], {'Auth-Token': authToken});
                    restEndPoint.updateOptions("", {conditions: {type: "theme"}});
                    return restEndPoint.getData("")
                }
            ).then(
            themeData => {
                instance.themeData = instance.updateMuiTheme(themeData[0].data);
                return instance.props.favcyContext.sdkInstance.getApplication().getWidgetData(this.props.type, this.props.id, this.props.options);
            }
        )
            .then(
                response => {
                    let dataExist = false;
                    if (response !== undefined && response !== null) {
                        dataExist = true;
                        instance.setState({
                            dataList: response
                        });
                    }
                    instance.setState({
                        dataExist: dataExist
                    })

                },
                error => {
                    console.log(error.stack);
                }
            );
    };

    sendRequest() {
        let instance = this;
        instance.setState({showLoader: true});
        instance.props.favcyContext.app.callApi(instance.mWidgetType, instance.mWidgetID, instance.mAction, instance.mData, instance.mMethod, instance.mConfig).then(
            response => {
                instance.mResolve(response);
                instance.setState({showLoader: false});
            },
            error => {
                instance.mReject(error);
                instance.setState({showLoader: false});
            }
        );
    }

    checkPost(widgetType, widgetID, action, data, method, config) {
        let instance = this;
        return new Promise(function (resolve, reject) {
            FavcyApp.getInstance().subscribeEvents(FavcySDKEvent.eventNames.onLoginCancelled, function () {
                reject(new Error("Your entry has been cancelled. Please try again"));
            });
            instance.setClassVariables(widgetType, widgetID, action, data, method, config, resolve, reject);
            if (!FavcyApp.getInstance().getUser().isLoggedIn()) {
                instance.props.favcyContext.app.getWidgetHelper(instance.props.favcyContext).triggerUserLogin(
                    status => {
                        if (status === false) {
                            reject(new Error("Your entry has been cancelled. Please try again"));
                        }
                        else {
                            instance.sendRequest();
                        }
                    }
                );
            } else {
                instance.setClassVariables(widgetType, widgetID, action, data, method, config, resolve, reject);
                instance.sendRequest();
            }
        })

    }

    setClassVariables(widgetType, widgetID, action, data, method, config, resolve, reject) {
        this.mWidgetType = widgetType;
        this.mWidgetID = widgetID;
        this.mAction = action;
        this.mData = data;
        this.mMethod = method;
        this.mConfig = config;
        this.mResolve = resolve;
        this.mReject = reject;
    }

    componentDidMount() {
        this.requestData();
        this.loadWidget();
    }

    loadWidget = () => {
        const instance = this;
        instance.LocalWidget = ModuleLoadable({
            loader: () => instance.props.widget
        });
    };


    componentWillReceiveProps(nextProps) {
        const instance = this;
        if (instance.props.isUserLoggedIn !== nextProps.isUserLoggedIn) {
            instance.requestData();
        }

    }

    render() {
        const instance = this;
        return instance.state.dataList !== null && instance.LocalWidget &&
            <MuiThemeProvider muiTheme={getMuiTheme(instance.themeData)}>
                <instance.LocalWidget
                    theme={instance.themeData} {...instance.props} {...instance.stateModifiers} {...instance.state} {...instance.props.favcyContext}/>
            </MuiThemeProvider>
    }

    updateMuiTheme(favcyTheme) {

        return {
            palette: {
                primary1Color: favcyTheme['primary-color'],
                primary2Color: favcyTheme['primary-color-dark'],
                primary3Color: favcyTheme['primary-color-light'],
                accent1Color: favcyTheme['accent-color'],
                accent2Color: favcyTheme['accent-color-dark'],
                accent3Color: favcyTheme['accent-color-light'],
                textColor: favcyTheme['primary-text'],
                alternateTextColor: favcyTheme['secondary-text'],
                borderColor: favcyTheme['tribe-divider-color'],
                iconColor: favcyTheme['tribe-icons-color'],
                confirmColor: favcyTheme['confirm-color'],
                cancelColor: favcyTheme['error-color']
            },
            svgIcon: {
                color: favcyTheme['accent-color']
            }
        };
    }
}

export default withFavcyContext(LocalEmbedder);
