import {Mutex} from "async-mutex/lib/index";
import FavcyApplication from "./FavcyApplication";
import {FavcyEventManager} from "../utilities/eventManager";
import FavcyUser from "./FavcyUser";
import {FavcySDKEvent} from "./FavcySDKEvent";


class FavcySDK {

    constructor() {

        this.mAppId = null;
        this.mUser = null;
        this.mApplication = null;
        this.mEventManager = null;
        this.mMutex = new Mutex();
    }

    subscribe() {
        let instance = this;
        instance.mEventManager.subscribe(FavcySDKEvent.eventNames.onWalletTransaction, function (eventName, token) {
                 instance.mUser.refreshData()
        });

        instance.mEventManager.subscribe(FavcySDKEvent.eventNames.onPassPointTransaction, function (eventName, token) {
            instance.mUser.refreshData()
        });
    }



    init = (appId, authToken) => {

        const instance = this;
        instance.mAppId = appId;
        instance.mApplication = new FavcyApplication(appId);
        instance.mUser = new FavcyUser(instance.mApplication);
        instance.mEventManager = new FavcyEventManager();

        instance.subscribe();


        return instance.mApplication.init(instance.mEventManager)
            .then(
                response => instance.mUser.init(authToken, instance.mEventManager)

            ).then(
                result => {
                    instance.subscribeTokenChange();
                    return instance;
                }
            ).catch(error => {
                console.log(error.stack);
                throw new Error("Favcy SDK initialization failed");
            });

    };


    getApplication = () => {
        return this.mApplication;
    };

    getClient = () => {
        return this.getApplication().getClient();
    };


    subscribeEvents = (eventName, callBack) => {
        this.mEventManager.subscribe(eventName, callBack);
    };

    getUser = () => {
        return this.mUser;
    };

    subscribeTokenChange() {

        const instance = this;
        instance.mEventManager.subscribe(FavcySDKEvent.eventNames.onTokenChange, function (eventName, token) {
            const role = instance.mApplication.getClient().getDataFromToken('role');
            if (role === 'guest') {
                instance.mUser.logout();
            } else {
                instance.mUser.login(token);
            }

        });
    }
}


export default FavcySDK;
