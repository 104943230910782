import React from "react";
import {withStyles} from "@material-ui/core";

let FavcyContext = null;
let styles = null;

export function getFavcyContext(value) {
    FavcyContext = React.createContext(value);
    styles =  value ? value.style : {};
    return FavcyContext;
}

export function withFavcyContext(Component) {
    return withStyles(styles)(function FavcyContextComponent(props) {

        return (
            <FavcyContext.Consumer>
                {value => {
                    return (<Component {...props} favcyContext={value}/>);
                }}
            </FavcyContext.Consumer>
        );
    });
}
