import 'react-app-polyfill/ie11';
import {Mutex} from "async-mutex";
import FavcySDK from "../sdk-js/main/FavcySDK";
import {FavcyEventManager} from "../sdk-js/utilities/eventManager";


let instance = null;
let enforcerSymbol = Symbol();

/**
 * @classdesc FavcyApp is the main singleton class for the Favcy Application.
 * It provides the interface for the applications to use the Favcy services.
 * The class also provides an interface to the main entities. viz.
 * Application (Provides Application & Brand Settings)
 * User (Provides User Details and Actions)
 * Widgets (Provides Widget Settings and Actions).
 *
 * @class
 * @hideconstructor
 */
class FavcyApp {

    /**
     * @constructor
     * @private
     */
    constructor(symbol) {
        if (symbol === undefined || symbol !== enforcerSymbol) {
            throw  new Error("Instantiation not allowed. Call static method getInstance()");
        }

        this.mAppId = 0;
        this.mMutex = new Mutex();
        this.mSDKInstance = new FavcySDK();
        this.mEventManager = new FavcyEventManager();
    }

    /**
     * @static
     * Static method to return the singleton instance of {@link FavcyApp}
     * @returns FavcyApp
     * @function FavcyApp#getInstance
     */
    static getInstance = () => {
        if (!instance) {
            instance = new FavcyApp(enforcerSymbol);
        }
        return instance;
    };

    /**
     * @function FavcyApp#init
     * @param appId - Application id of the brand.
     * @param [authToken] - If provided, the authToken overrides the previously
     * stored authToken.
     * @return Promise - When resolved, returns instance of {@link FavcyApp}
     *
     * @tutorial FavcyApp_init
     */
    init = (appId, authToken) => {

        instance.mAppId = appId;
        let mutexRelease = null;

        return this.mMutex.acquire().then(
            release => {
                mutexRelease = release;
                return instance.mSDKInstance.init(appId, authToken);
            }).then(
            sdkInstance => {
                mutexRelease();
                return instance;
            }
        ).catch(error => {
            console.log(error.stack);
            if (mutexRelease) {
                mutexRelease();
            }
            throw new Error("Initialization Failed ");
        });
    };

    /**
     * @function FavcyApp#getData
     * @desc The function is used to fetch the application data
     * @return Object - with application settings
     */
    getData = () => {
        return this.mSDKInstance.getApplication().getData();
    };

    /**
     * @function FavcyApp#getUser
     * @desc Function to fetch {@link FavcyUser} object for the current user.
     * @return {FavcyUser} - Instance of the current User, providing user information.
     * @tutorial User_Examples
     */
    getUser = () => {
        console.log(this.mSDKInstance.getUser());
        return this.mSDKInstance.getUser();
    };

    /**
     * @function FavcyApp#subscribeEvents
     * @desc Function to subscribe for Events.
     * The {@link FavcySDK} fires different events at different actions,
     * Subscribe these raised events in order to implement application
     * logic based on the event event handler.
     * @return {FavcyEventManager}
     * @tutorial SDK_subscribeEvents
     */
    subscribeEvents = (eventName, callBack) => {
        return this.mSDKInstance.subscribeEvents(eventName, callBack);
    };

    embedWidget = (elementId, type, id, options) => {
        const instance = this;
        return import("main/FavcyAppReact").then(
            response => {
                const component = new response.default();
                component.embedWidget(instance.mSDKInstance, elementId, type, id, options);
            }
        );
    };

    /**
     * @function FavcyApp#api
     * @desc The function provides an interface to access the Favcy Platform and widget
     * APIs
     * @param path - endPoint Path
     * @param method - enum(get, post)
     * @param options - object - with arguments.
     * @return Promise - when resolved returns an object with the ap response/data.
     */
    api = (path, method, options) =>{
        /** TODO: Complete the function **/
    };
}

export default FavcyApp;
window.FavcyApp = FavcyApp;
