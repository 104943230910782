/**
 * Created by favcy-pc on 10-06-2017.
 */
 import {objExtend, SDKCommon , gaEventTrigger} from "../common/common";
 import {FavcyYoutubeTracker} from "../trackers/youtubeTracker";
 import {FacebookTracker} from "../trackers/facebookTracker";
 import {TwitterTracker} from "../trackers/twitterTracker";
 import RestEndPoint from "sdk-js/main/RestEndPoint";
 
 
 SDKCommon();
 
 function eventListener (event={}){
     let {
         target:{
             dataset = {}
         } ={}
     } = event;
     if(dataset.analytics !== "true") return 0;
     
     if(!dataset["gaCategory"]) return "Analytics Category Required";
     
     if (!dataset["gaAction"]) return "Analytics Category Action";
     
     gaEventTrigger(
         dataset["gaCategory"],
         dataset["gaAction"],
         dataset["gaLabel"],
         dataset["gaValue"]
     );
 
 }
 
 function addEventListeners (){
     document.addEventListener("click", eventListener);
 }
 
 
 export function Tracker(applicationObj, userObject) {
 
     let defaultConfg = {
         fb: {
             enabled: false
         },
         jw_player: {
             enabled: false
         },
         youtube_player: {
             enabled: false
         },
         pagevisit: {
             enabled: false,
             threshold: 0.6
         },
         twitter: {
             enabled: false
         }
     };
 
     let promiseArray = [];
     let instance = this;
     let mConfig = null;
     let mApplication = applicationObj;
     let mUser = userObject;
 
 
     this.initializeGA = function(tracker){
         let scriptId = 'ga-script';
         let gaScript = document.getElementById(scriptId);
         if(!gaScript) {
             gaScript = document.createElement('script');
             gaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id='+tracker);
             gaScript.setAttribute('async', '');
             gaScript.id = scriptId;
             document.body.appendChild(gaScript);
 
             let initializeScript  = document.createElement('script');
             initializeScript.innerHTML = "window.dataLayer = window.dataLayer || [];\n" +
                 " function gtag(){dataLayer.push(arguments);}\n" +
                 " gtag('js', new Date());\n" +
                 "\n" +
                 " gtag('config', '"+tracker+"');";
             document.body.appendChild(initializeScript);
         }
     };
 
     this.initializeGTag = function(tracker) {
         let scriptId = 'ga-tag-script';
         let gaScript = document.getElementById(scriptId);
         if(!gaScript) {
             gaScript = document.createElement('script');
             gaScript.id = scriptId;
             gaScript.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
                 "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
                 "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
                 "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
                 "})(window,document,'script','dataLayer','"+tracker+"');";
             document.head.appendChild(gaScript);
 
             let initializeScript  = document.createElement('noscript');
             let html = "<iframe src='https://www.googletagmanager.com/ns.html?id='"+tracker+"'\n" +
                 "height='0' width='0' style='display:none;visibility:hidden'></iframe>";
             initializeScript.innerHTML = html;
             document.body.appendChild(initializeScript);
         }
     };
     this.initializeFbTag = function(tracker) {
         let scriptId = 'fb-tag-script';
         let fbScript = document.getElementById(scriptId);
         if(!fbScript) {
             fbScript = document.createElement('script');
             fbScript.id = scriptId;
             fbScript.innerHTML = "!function(f,b,e,v,n,t,s)\n"+
             "{if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n"+
             "n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n"+
             "if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n"+
             "n.queue=[];t=b.createElement(e);t.async=!0;\n"+
             "t.src=v;s=b.getElementsByTagName(e)[0];\n"+
             "s.parentNode.insertBefore(t,s)}(window, document,'script',\n"+
             "'https://connect.facebook.net/en_US/fbevents.js');\n"+
             "fbq('init', '" + tracker +"');\n"+
             "fbq('track', 'PageView');"
             document.head.appendChild(fbScript);
 
             let initializeScript  = document.createElement('noscript');
             let html = "<img height='' width='1' style='display:none\n'"+
             "src='https://www.facebook.com/tr?id="+ tracker + "&ev=PageView&noscript=1'"
             "/>";
             initializeScript.innerHTML = html;
             document.body.appendChild(initializeScript);
         }
     };
 
     this.initializeMsTag = function(tracker) {
         let scriptId = 'ms-tag-script';
         let msScript = document.getElementById(scriptId);
         if(!msScript) {
             msScript = document.createElement('script');
             msScript.id = scriptId;
             msScript.innerHTML = "(function(c,l,a,r,i,t,y){\n"+
                 "c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};\n"+
                 "t=l.createElement(r);t.async=1;t.src='https://www.clarity.ms/tag/'+i;\n"+
                 "y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);\n"+
                 "})(window, document, 'clarity', 'script', '"+ tracker +"');"
             document.head.appendChild(msScript);
         }
     };
 
     this.initializeComScore = function(tracker){
         let scriptId = 'comscore-script';
         let gaScript = document.getElementById(scriptId);
         if(!gaScript) {
             gaScript = document.createElement('script');
             gaScript.id = scriptId;
             gaScript.async = true;
             gaScript.innerHTML = "var _comscore = _comscore || [];\n" +
                 "\n" +
                 "  _comscore.push({ c1: \"2\", c2: \""+tracker+"\" });\n" +
                 "\n" +
                 "  (function() {\n" +
                 "\n" +
                 "    var s = document.createElement(\"script\"), el = document.getElementsByTagName(\"script\")[0]; s.async = true;\n" +
                 "\n" +
                 "    s.src = (document.location.protocol == \"https:\" ? \"https://sb\" : \"http://b\") + \".scorecardresearch.com/beacon.js\";\n" +
                 "\n" +
                 "    el.parentNode.insertBefore(s, el);\n" +
                 "\n" +
                 "  })();";
             document.head.appendChild(gaScript);
 
             let initializeScript  = document.createElement('noscript');
             let html = "<img src='https://sb.scorecardresearch.com/p?c1=2&cv=2.0&cj=1&c2="+tracker+"' />";
             initializeScript.innerHTML = html;
             document.body.appendChild(initializeScript);
         }
     };
 
     this.init = function () {
         let authToken = mApplication.getClient().getAuthToken();
         return new Promise(function (resolve, reject) {
             import("resources/json/widgets").then(
                 config => config["setting-campaigns"]
             ).then(
                 config => {
                     const restEndPoint = new RestEndPoint("setting-campaigns", 0, config, {'Auth-Token': authToken});
                     restEndPoint.updateOptions("", {conditions: {type: "tracker"}});
                     return restEndPoint.getData("");
                 }
             ).then(
                 data => {
                     if(data && data.length && data[0].data !== undefined) {
                         if(data[0].data.analytics && data[0].data.analytics['ga-id']){
                             instance.initializeGA(data[0].data.analytics['ga-id']);
                         }
 
                         if(data[0].data.analytics && data[0].data.analytics['ga-tag']){
                             instance.initializeGTag(data[0].data.analytics['ga-tag']);
                         }
                         if(data[0].data.analytics && data[0].data.analytics['fb-pixel']){
                             instance.initializeFbTag(data[0].data.analytics['fb-pixel']); 
                         }
                         if(data[0].data.analytics && data[0].data.analytics['ms-clarity']){
                             instance.initializeMsTag(data[0].data.analytics['ms-clarity']); 
                         }
 
                         if(data[0].data.analytics && data[0].data.analytics['comscore']){
                             instance.initializeComScore(data[0].data.analytics['comscore']);
                         }
                         addEventListeners();
                         return objExtend(defaultConfg, data[0].data)
                     } else {
                         return defaultConfg;
                     }
                 }
             ).then(
                 config => {
                     mConfig = config;
                     startSocialTracking();
                    // defaultTracking();
                     return Promise.allKeys(promiseArray);
                 }
             ).then(
                 response => resolve(instance)
             ).catch(
                 error => reject(error)
             );
         })
 
     };
 
 
     function defaultTracking() {
         timeSpentTracker();
     }
 
     function timeSpentTracker() {
 
         if (mUser.isLoggedIn()) {
             setTimeout(function () {
                 mUser.postData("action-transactions", {action_code: "timespent"})
             }, 3000);
         }
     }
 
     function startYoutubeTracking() {
         let youtubeTracker = new FavcyYoutubeTracker(mUser);
         promiseArray["youtube_player"] = youtubeTracker.initialize();
     }
 
     function startJWPlayerTracking() {
         /*promiseArray["jw_player"] = loadjscssfile(configuration.widgetBaseURL + "/js/jwplayer-track-event.js", "js");*/
     }
 
     function startTwitterTracking() {
         let twitterTracker = new TwitterTracker();
         promiseArray["twitter"] = twitterTracker.init();
     }
 
     function startFBTracking() {
         let fbTracker = new FacebookTracker(mUser);
         promiseArray["fb"] = fbTracker.initialize();
     }
 
     function startSocialTracking() {
 
         if (mConfig.fb.enabled) {
             startFBTracking();
         }
 
         if (mConfig.jw_player.enabled) {
             startJWPlayerTracking();
         }
 
         if (mConfig.youtube_player.enabled) {
             startYoutubeTracking();
         }
 
         // if (mConfig.pagevisit.enabled) {
         //     startPageVisitTracking();
         // }
 
         if (mConfig.twitter.enabled) {
             startTwitterTracking();
         }
     }
 
     function startPageVisitTracking() {
 
         let isPointPassed = false;
         window.onscroll = function (e) {
             if ((window.scrollY > (document.body.offsetHeight * 0.3)) && !isPointPassed) {
                 mUser.postData("action-transactions", {action_code: "pagevisit"}).catch(
                     error => console.log("ERROR: Failed to Pass Point for page Visit")
                 );
                 isPointPassed = true;
             }
         };
     }
 }
 