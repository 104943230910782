import {Tracker} from "./FavcyTracker";
import RestEndPoint from "sdk-js/main/RestEndPoint";
import {FavcySDKEvent} from "./FavcySDKEvent";
import {objectToFormData} from "../common/common";
import {parseJwt} from "../common/common";


class FavcyUser {

    constructor(applicationObject) {
        this.mApplication = applicationObject;
        this.mRole = 'guest';
        this.mEventManager = null;
        this.mRestClient = null;
        this.mUserData = null;
        this.mClient = this.mApplication.getClient();
    }

    raiseEvent(eventName, eventData) {
        if (this.mEventManager) {
            this.mEventManager.fire(eventName, eventData);
        }
    }

    refreshData() {
        const instance = this;
        const appData = instance.mApplication.getData();
        let options={
            with: {
                memberships: {
                    conditions: {
                        brand_id: appData.brand.id},
                    with : {
                        custom_fields : {},
                        custom_data : {}
                    }
                },
                socials: {},
                wallets: {}
            }
        };
        instance.mRestClient.updateOptions('',options);
        return instance.mRestClient.getData('').then(
            data => instance.checkForDataChange(data)
        );
    }

    checkForDataChange(data) {
        const instance = this;
        const oldData = JSON.parse(JSON.stringify(instance.mUserData));
        instance.mUserData = data;
        if (oldData) {
            if (JSON.stringify(oldData) !== JSON.stringify(data)) {
                instance.raiseEvent(FavcySDKEvent.eventNames.onUserObjectChange, instance.mUserData);
            }
        }
        return instance.mUserData;
    }

    init = (authToken, eventManager) => {

        const instance = this;
        if (authToken === undefined || authToken === null || authToken === "") {
            authToken = instance.mApplication.getClient().getAuthToken();
        }

        let role = instance.mApplication.getClient().getDataFromToken("role");
        let userId = role === 'user' ? 'me' : 'guest';

        if (eventManager !== undefined && typeof eventManager === 'object') {
            instance.mEventManager = eventManager;
        }

        return import('resources/json/platform')
            .then(
                config => {
                    instance.mRestClient = new RestEndPoint('users', userId, config['users'], {'Auth-Token': authToken});
                    return instance.refreshData();
                })
            .then(
                userData => {
                    instance.mTracker = new Tracker(instance.mApplication, instance);
                    return instance.mTracker.init();
                })
            .then(
                result => {
                    return instance;
                }
            );
    };

    isLoggedIn = () => {
        const instance = this;
        return instance.mApplication.getClient().getDataFromToken("role") === 'user';
    };

    getData = () => {
        return this.mUserData;
        
        //To be checked
        // const rVal = JSON.parse(JSON.stringify(this.mUserData));
        // return rVal;
    };

    getUserWidgetTokenJWT = (token) => {
      return parseJwt(token);
    };

    logout = (token) => {
        const instance = this;
        instance.mClient.setAuthToken(token);
        return instance.init(token, instance.mEventManager)
            .then(
                response => {
                    instance.raiseEvent(FavcySDKEvent.eventNames.onUserLogout, instance.mApplication.getClient().getAuthToken());
                    return token;
                }
            )
            .catch(error => console.log("Error: " + error.messaage));
    };

    login = (token, delay, onBoardingData) => {
        const instance = this;
        instance.mClient.setAuthToken(token);
        return new Promise(function (resolve, reject) {
            instance.init(token, instance.mEventManager).then(
                response => {
                    resolve(response);
                    delay = delay !== undefined ? delay : 0;
                    setTimeout(function () {
                        instance.raiseEvent(FavcySDKEvent.eventNames.onUserLogin, instance.mApplication.getClient().getAuthToken());
                        if(onBoardingData && onBoardingData.isSignUp){
                            instance.raiseEvent(FavcySDKEvent.eventNames.onUserSignUp, {token: instance.mApplication.getClient().getAuthToken(),enableOnBoarding: onBoardingData.enableOnBoarding});
                        }
                    }, delay);
                },
                error => reject(error)
            )
        });
    };

    postData = (attribute, objectData, optionalId) => {
        const instance = this;
        let formData = objectToFormData(objectData, '', []);
        return instance.mRestClient.postData(attribute, formData, optionalId);
    };

}

export default FavcyUser;
