import axios from "axios";
import { getFormDataSize } from './common';
let maxReqSize = 15;
axios.interceptors.request.use((req)=>{
    if(req.method === "post" && req.data && req.data.entries){
        let reqSizeArr = getFormDataSize(req.data);
        let reqSize = 0;
        reqSizeArr.forEach(({ size })=>{
            if(size)
                reqSize +=size;
        })
        reqSize = reqSize / (1024 * 1024);
        if(reqSize > maxReqSize){
            return Promise.reject(`Media size too large. Media uploads can't be greater ${maxReqSize} MB `);
        }
    };
    return req;  
})

export function HttpHandler(eventManager) {


    let mConfig = {
        'Accept': 'application/json'
    };

    this.addHeader = function (key, value) {
        mConfig[key] = value;
    };

    this.addHeaders = function (configHeader) {
        mConfig = Object.assign(mConfig, configHeader);
    };

    this.httpGet = function (url) {
        return axios.get(url, {headers: mConfig});
    };


    this.httpPut = function (url, data, callback) {

        return axios.put(url, data, {headers: mConfig});
    };


    this.httpPost = function (url, data , config={}) {
        return axios.post(url, data, {headers: mConfig, ...config});
    };

    this.httpPostFile = function (url, data, callback) {

        return axios.post(url, data, {headers: mConfig});
    };

    this.httpDelete = function(url) {
        return axios.delete(url, {headers: mConfig});
    }
}
