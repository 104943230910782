import DynamicLoginWrapper from "./DynamicLoginWrapper";
import ReactDOM from "react-dom";
import React from "react";

export class  WidgetHelper {

    constructor(favcyContext, enableOnBoarding) {
        this.mFavcyContext = favcyContext;
        this.enableOnBoarding = enableOnBoarding;
    }


    /**
     * Function is use to trigger dynamic login.
     * @param callback when login is completed, options are the settings you provide to FavcyLogin component
     */
    triggerUserLogin = (callback, options) => {
        let loginDiv = document.createElement('div');
        loginDiv.id = 'loginDiv' + (Math.random() * 10000).toFixed(0);
        loginDiv.style.display = "block";
        let settings = {
            renderMode: "link",
            bodyText: (options !== undefined && options.login !== undefined && options.login.text !== undefined && options.login.text !== null) ? options.login.text : "Now" +
                " lay a claim on your entry by Logging In.",
            headerTitle: (options !== undefined && options.login !== undefined && options.login.header !== undefined && options.login.header !== null) ? options.login.header : "Login To Continue",
            icon: (options !== undefined && options.login !== undefined && options.login.icon !== undefined && options.login.icon !== null) ? options.login.icon : "fa" +
                " fa-hand-peace-o",
            delay: 3000
        };
        document.body.appendChild(loginDiv);

        this.addLoginButton(loginDiv, function (token) {
            if(loginDiv){
                loginDiv.parentNode.removeChild(loginDiv);
            }
            callback(token);
        }, settings).then(
            response => {
                console.log(response);
            }
        )
    };

    /**
     * Function is use to add virtual login button in the div created
     * @param elementId on which button will be mounted
     */
    addLoginButton = (element, callback, settings) => {
        const instance = this;
        return new Promise(function (resolve, reject) {

            let options = {};
            if (callback !== undefined){
                options.logoutCallBack = callback;
                options.loginCallBack = callback;
            }

            options.settings = settings;
            options.settings.enableOnBoarding = instance.enableOnBoarding;

            let rVal = <DynamicLoginWrapper options={options}  favcyContext={instance.mFavcyContext}  />;
            ReactDOM.render(rVal, element);
            resolve(rVal);

        });
    };
}