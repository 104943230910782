/**
 * Created by rajachhabra on 10/01/18.
 */
/**
 * FavcyEventsManager
 * @constructor
 */
import {EventsManager} from "events-manager";
import {objExtend} from "../common/common";

export function FavcyEventManager() {

    function InnerClass() {
        EventsManager.call(this);
    }

    let mEventManager = new InnerClass();
    let mPause = false;
    let mPendingList = {};

    /**
     * Use this function to subscribe to any raised event.
     * @function
     * @param eventName {string} Should be a valid event name.Please refer the constants in the class. e.g. {@link FavcySDKEvent.eventNames.onPassPoint}
     * @param callback {function} Expects a callback.
     */
    this.subscribe = function (eventName, callback) {
        mEventManager.on(eventName, function (eventData) {
            callback(eventName, eventData);
        })
    };

    /**
     *
     * @param eventName {string} Should be a valid event name.Please refer the constants in the class. e.g. {@link FavcySDKEvent.eventNames.onPassPoint}
     * @param eventData {(string|string[]|Object[])} Define event data to be fired with the specified event
     * @returns {*}
     */
    this.fire = function (eventName, eventData) {
        if (mPause) {
            addToPending(eventName, eventData);
            return;
        }
        return mEventManager.fire(eventName, eventData);
    };

    this.pause = function () {
        mPause = true;
    };

    this.resume = function () {
        Object.keys(mPendingList).map(function (key, index) {
            setTimeout(function () {
                mEventManager.fire(key, mPendingList[key]);
            }, 3000);
            return mPendingList[key];
        });
        mPause = false;
    };

    function addToPending(eventName, eventData) {
        let data = {};
        data[eventName] = eventData;
        mPendingList = objExtend(mPendingList, data);
    }
}
