/**
 * Created by rajachhabra on 02/04/18.
 */
import FavcySDK from "../main/FavcySDK";

export class TwitterTracker {

    constructor() {
        this.registerEvents = this.registerEvents.bind(this);
    }

    init() {
        const instance = this;
        return new Promise(function (resolve, reject) {
            try {
                instance.registerEvents = instance.registerEvents.bind(this);
                if (!window.twttr) {
                    window.twttr = (function (d, s, id) {
                        let js, fjs = d.getElementsByTagName(s)[0],
                            t = window.twttr || {};
                        if (d.getElementById(id)) return t;
                        js = d.createElement(s);
                        js.id = id;
                        js.src = "https://platform.twitter.com/widgets.js";
                        fjs.parentNode.insertBefore(js, fjs);
                        instance.attachTwitterReady(t);
                        return t;
                    }(document, "script", "twitter-wjs"))
                }

                if (window.twttr) {
                    instance.attachTwitterReady(window.twttr);
                }

                window.twttr.ready(
                    function (twttr) {
                        instance.registerEvents();
                    }
                );
                resolve(instance);
            } catch (error) {
                reject(error);
            }
        })
    }

    attachTwitterReady(t) {
        t._e = [];
        t.ready = function (f) {
            t._e.push(f);
        };
    }

    registerEvents() {
        window.twttr.events.bind('click', this.handleEvents.bind(this));
        window.twttr.events.bind('tweet', this.handleEvents.bind(this));
        window.twttr.events.bind('retweet', this.handleEvents.bind(this));
        window.twttr.events.bind('like', this.handleEvents.bind(this));
        window.twttr.events.bind('follow', this.handleEvents.bind(this));
    }

    handleEvents(event) {
        FavcySDK.getInstance().getUser().handleUserEvent("twitter-" + event.type, event.data);

    }


}
