/**
 * Created by rajachhabra on 07/02/18.
 */
import React from "react";
import IframeEmbedder from "./IframeEmbedder";
import LocalEmbedderNew from "./LocalEmbedderNew";
import LocalEmbedder from "./LocalEmbedder";
import {withFavcyContext} from "./FavcyContext";
import {FavcySDKEvent} from "../../sdk-js/main/FavcySDKEvent";

class EmbeddedWidget extends React.Component {
    getLocalWidgetOld = (type) => {
        let rVal = null;

        switch (type) {
            case 'audio':
                rVal = import("./audio/AudioWidget");
                break;

            case 'container':
                rVal = import("./container/Container");
                break;

            case 'downloads':
                rVal = import("./download/Download");
                break;

            case 'selfies':
                rVal = import("./selfie/Selfie");
                break;

            case 'membershipLevel':
                rVal = import("components/tribe/modules/user/membership/Membership");
                break;

            // case 'games':
            //     rVal = import("./games/FreeCoins");
            //     break;

            case 'wallet':
                rVal = import("./shops/offers/Wallet");
                break;

            // case 'community':
            //     rVal = import("./communityFeed/CommunityFeed");
            //     break;

            case 'video':
                rVal = import("./video/SponsorVideo");
                break;

            case 'eventCalendar':

                 rVal = import("./eventCalendar/FbEventCalendar");
                break;

            case 'multiWidget':
                rVal = import("./multiWidget/MultiWidget");
                break;

            case 'coupon':
                rVal = import("./coupon/Coupon");
                break;

            default:
                rVal = null;
        }

        return rVal;
    };
    getLocalWidget = (type) => {
        let rVal = null;
        let instance = this;
        switch (type) {

            case 'downloads-new':
                rVal = import("./download-new/Download");
                instance.isEndpointPresent = false;
                break;

            case 'badges':
                rVal = import("./badges/Badges");
                break;

            case 'myActivity':
                rVal = import("./myActivity/MyActivity");
                instance.loginReRender = true;
                break;

            // case 'notifications':
            //     rVal = import("./communityFeed/CommunityFeedData");
            //     break;
            
            case 'lock':
                rVal = import("./lock/LockWidget");
                break;

            // case 'fantasy':
            //     rVal = import("./fantasy/Fantasy");
            //     break;

            // case 'fantasy-new':
            //     rVal = import("./fantasy-new/Fantasy");
            //     break;

            // case 'fantasy-challenges':
            //     rVal = import("./cfl/challenge/Challenge");
            //     instance.renderNoResult = true;
            //     break;

            case 'shops':
                rVal = import("./shops/Shops");
                break;

            case 'stores':
                rVal = import("./stores/Stores");
                break;

            case 'storeProducts':
                rVal = import("./stores/Product/OffersWrapper");
                break;

            case 'shopOffers':
                rVal = import("./shops/shopOffers/OffersWrapper");
                break;

            // case 'community':
            //     rVal = import("./communityFeed/CommunityFeed");
            //     break;

            case 'referral':
                rVal = import("./referral/Referral");
                break;

            case 'tribe':
                rVal = import("components/tribe/App");
                break;

            case 'bot':
                rVal = import("components/bot/BotContainer");
                break;

            case 'login':
                rVal = import("./login/FavcyLogin");
                break;

            case 'logout':
                rVal = import("./login/FavcyLogout");
                break;

            case 'actions':
                rVal = import("./engagementActions/ActionList");
                break;
            // case 'cflconsumer':
            //     rVal = import("./cfl/consumer/Consumer");
            //     break;
            case 'clubsubscription':
                rVal = import("./club/customer/Customer");
                instance.isEndpointPresent = false;
                break;
            // case 'cflcustomer':
            //     rVal = import("./cfl/customer/Customer");
            //     break;

            // case 'dukaansubscription':
            //     rVal = import("./dukaan/customer/Customer");
            //     break;

            case 'tugsubscription':
                rVal = import("./tug/customer/Customer");
                break;

            /* @TODO : chatbot is for legacy support */
            case 'chatbot':
            case 'chat':
                rVal = import("components/chat/ChatBot");
                break;

            case 'blob':
                rVal = import("components/chat/Blob");
                break;

            // case 'fitness':
            //     rVal = import("./fitness/Fitness");
            //     break;
            case 'event':
                rVal = import("./events/Events");
                  instance.loginReRender = true;
                break;
            case 'leaderBoard':
                rVal = import("./leaderboard/Leaderboard");
                instance.loginReRender = true;
                break;
            case 'payment':
                rVal = import("./donation/Donation");
                break;
            case 'onBoarding':
                rVal = import("./onBoarding/OnBoardingDialog");
                instance.loginReRender = true;
                break;
            case 'userWallet':
                rVal = import("./wallet/Wallet");
                instance.loginReRender = true;
                break;
            case 'myOrders':
                rVal = import("./orders/MyOrders");
                instance.loginReRender = true;
                instance.renderNoResult = true;
                break;
            case 'userApps':
                rVal = import("./UserApps");
                instance.loginReRender = true;
                instance.isEndpointPresent = false;
                break;
            // case 'sowlswitchsubscription':
            //     rVal = import("./sowlSwitch/customer/Customer");
            //     instance.isEndpointPresent = false;
            //     break;
            case 'pehchansubscription':
                rVal = import("./ecommerce/customer/pehchanSubscription/Customer");
                break;
            // case 'fangreetsubscription':
            //     rVal = import("./fanGreet/customer/Customer");
            //     break;
            case 'question':
                rVal = import("./question/QuestionWrapper");
                break;
            // case 'krishisubscription':
            //     rVal = import("./agriculture/customer/krishiSubscription/Customer");
            //     break;
            // case 'zoimealsubscription':
            //     rVal = import("./canteen/customer/canteenSubscription/Customer");
            //     break;
            // case 'zoimealmasteradmin':
            //     rVal = import("./canteen/masterAdmin/MasterAdminWrapper");
            //     instance.loginReRender = true;
            //     instance.isEndpointPresent = false;
            //     break;
            // case 'jobcaddysubscription':
            //     rVal = import("./jobcaddy/customer/jobcaddySubscription/Customer");
            //     break;
            // case 'jobcaddymasteradmin':
            //     rVal = import("./jobcaddy/masterAdmin/MasterAdminWrapper");
            //     instance.loginReRender = true;
            //     instance.isEndpointPresent = false;
            //     break;
            // case 'pwnacademysubscription':
            //     rVal = import("./pwnacademy/customer/pwnacademySubscription/Customer");
            //     break;
            // case 'fannomixsubscription':
            //     rVal = import("./fannomix/customer/fannomixSubscription/Customer");
            //     break;
            // case 'fannomixmasteradmin':
            //     rVal = import("./fannomix/masterAdmin/MasterAdminWrapper");
            //     instance.loginReRender = true;
            //     instance.isEndpointPresent = false;
            //     break;
            // case 'callxpsubscription':
            //     rVal = import("./callxp/customer/Customer");
            //     break;
            // case 'sportospotsubscription':
            //     rVal = import("./sportospot/customer/subscription/Customer");
            //     break;
            // case 'sportospotmasteradmin':
            //     rVal = import("./sportospot/masterAdmin/MasterAdminWrapper");
            //     instance.loginReRender = true;
            //     instance.isEndpointPresent = false;
            //     break;
            // case 'happysoulsubscription':
            //     rVal = import("./happysoul/customer/happysoulsubscription/Customer");
            //     break;
            // case 'majigsubscription':
            //     rVal = import("./majig/customer/majigsubscription/Customer");
            //     break;
            // case 'skillskonnectsubscription':
            //     rVal = import("./skillskonnect/customer/subscription/Customer");
            //     break;
            // case 'skillskonnectmasteradmin':
            //     rVal = import("./skillskonnect/masterAdmin/MasterAdminWrapper");
            //     instance.loginReRender = true;
            //     instance.isEndpointPresent = false;
            //     break;
            case 'palatemasteradmin':
                rVal = import("./palate/masterAdmin/MasterAdminWrapper");
                instance.loginReRender = true;
                instance.isEndpointPresent = false;
                break;
            case 'palatesubscription':
                rVal = import("./palate/customer/palateSubscription/Customer");
                break;
            // case 'skillkonnectprofile':
            //     rVal = import("./skillskonnect/profile/ProfileWrapper");
            //     instance.loginReRender = true;
            //     instance.isEndpointPresent = false;
            //     break;
            // case 'happysoulvendor':
            //     rVal = import("./happysoul/vendor/Vendor");
            //     instance.loginReRender = true;
            //     instance.isEndpointPresent = false;
            //     break;
            // case 'happysoulmasteradmin':
            //     rVal = import("./happysoul/masteradmin/MasterAdminWrapper");
            //     instance.loginReRender = true;
            //     instance.isEndpointPresent = false;
            //     break;
            // case 'majigmasteradmin':
            //     rVal = import("./majig/masteradmin/MasterAdminWrapper");
            //     instance.loginReRender = true;
            //     instance.isEndpointPresent = false;
            //     break;
            // case 'majigmanufacturer':
            //     rVal = import("./majig/manufacturer/Manufacturer");
            //     instance.loginReRender = true;
            //     instance.isEndpointPresent = false;
            //     break;
            default:
                rVal = null;
        }

        return rVal;

    };

    constructor(props) {
        super(props);
        const instance = this;
        instance.loginReRender = false;
        let local = instance.getLocalWidget(props.type);
        const isOld = local == null;
        local = local ? local : instance.getLocalWidgetOld(props.type);
        instance.state = {
            local: local,
            isOld: isOld,
        };

        props.favcyContext.sdkInstance.subscribeEvents(FavcySDKEvent.eventNames.onUserObjectChange, function (eventName, userData) {
            instance.setState({});
        });
    }

    render() {
        const instance = this;
        const sdkInstance = instance.props.favcyContext.sdkInstance;



        let options = instance.props.options || {};
        //Adding config if we want to rerender the plugin on login
        options.loginReRender = instance.loginReRender;
        options.renderNoResult = instance.renderNoResult || false;
        options.isEndpointPresent = instance.isEndpointPresent !== undefined ? instance.isEndpointPresent : true;


        let element = <IframeEmbedder {...instance.props} sdkInstance={sdkInstance}/>;


        if (instance.state.local) {
            if (instance.state.isOld) {
                element = <LocalEmbedder
                    type={instance.props.type}
                    id={instance.props.id}
                    widget={instance.state.local}
                    options={options}
                    reference={instance.props.reference}

                />;
            } else {
                element = <LocalEmbedderNew
                    type={instance.props.type}
                    id={instance.props.id}
                    widget={instance.state.local}
                    options={options}
                    reference={instance.props.reference}
                />;
            }
        }
        return element;
    }
}

export default withFavcyContext(EmbeddedWidget);
