import React from 'react';

const defaultUrlMap = require('resources/json/widgetUrlMap.json');

class IframeEmbedder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            randomID: null,
            widgetUrl: null,
            mUrlMap: defaultUrlMap
        };

        const application = props.sdkInstance.getApplication();
        this.state.randomID = props.type + (Math.random() * 10000).toFixed(0);
        this.state.widgetUrl = application.getWidgetPreviewUrl(props.type, props.id) + "&blockID=" + this.state.randomID;
    }

    componentDidMount() {
        let instance = this;
        document.getElementById(instance.state.randomID).addEventListener('load', this.iframeLoaded(instance.props.options, instance.state.randomID,instance.props.favcyContext));
    }

    iframeLoaded(options, blockID, favcyContext) {
        window.addEventListener("message", function (e) {

            if (e.data.blockID !== blockID) {
                return;
            }

            if (e.data.type === "web_view_height") {
                if (options !== undefined && options !== null && options.autoResize === false) {
                    if (options.style !== undefined && options.style.width !== undefined && options.style.width !== null)
                        document.getElementById(e.data.blockID).style.width = options.width;
                    if (options.style !== undefined && options.style.height !== undefined && options.style.height !== null)
                        document.getElementById(e.data.blockID).style.height = options.height;


                }
                else if (e.data.type !== undefined && e.data.type !== null) {
                    document.getElementById(e.data.blockID).style.height = (parseInt(e.data.height, 10)) + "px";
                    if (options !== undefined && options !== null && options.bringNodeToView !== undefined) {
                        options.bringNodeToView();
                    }
                }
            }

            if (e.data.type === "widget_result") {
                if (options !== null && options !== undefined && options.callback !== undefined && typeof options.callback === "function") {
                    options.callback(e.data.widgetResult);
                }


            }

            if (e.data.type === "widget_login_request") {
                let iframeObj = document.getElementById(blockID);
                favcyContext.app.getWidgetHelper(favcyContext).triggerUserLogin(function (token) {
                    let data = {
                        type: "widget_login_response",
                        authToken: token,
                        blockID: e.data.blockID
                    };

                    iframeObj.contentWindow.postMessage(data, "*")
                }, options);
            }
        });
    }

    render() {
        let wUrl = this.state.widgetUrl;
        return (
            <div style={{margin:'5px'}}>
                <iframe title={this.props.type} id={this.state.randomID} src={wUrl}
                        style={{border: 'none', width: 100 + '%'}}/>
            </div>
        );

    }

}

export default IframeEmbedder;
