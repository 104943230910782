/**
 * Created by rajachhabra on 07/11/17.
 */


export function FavcySDKEvent(eventName) {

}

/** @enum */
FavcySDKEvent.eventNames = {
    /**  Event raised when  user receives brand points. Can be
     used to show notification to users */
    onPassPoint: "onPassPoint",
    /** Event raised, when a user login is detected */
    onUserLogin: "onUserLogin",
    /** Event raised, when a user logout is detected */
    onUserLogout: "onUserLogout",
    /** Event raised, when application initialization is completed */
    onApplicationInit: "onApplicationInit",
    /** Event raised, when user object changes */
    onUserObjectChange:"onUserObjectChange",
    /** Event raised, when an auth token is invalid */
    onInvalidToken: "onInValidToken",
    onTokenChange: "onTokenChange",
    /**Event raised, when login modal is closed */
    onLoginCancelled: "onLoginCancelled",
    /** Event raised if user signup **/
    onUserSignUp: "onUserSignUp",
    /**Event Raised Whenever Wallet Changes **/
    onWalletTransaction: "onWalletTransaction",
    /** Event Raise When a Transaction is Completed */
    onTransactionComplete  :"onTransactionComplete",

    /**Event Raise When a Pass Point Transaction Happens**/
    onPassPointTransaction: "onPassPointTransaction"

};

window.FavcySDKEvent = FavcySDKEvent;








