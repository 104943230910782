import {BaseVideoTracker} from "./BaseVideoTracker";
export function FacebookTracker(userObj) {
    this.initialize = function () {
        const instance = this;
        return new Promise(function (resolve, reject) {
            try {
                let e = document.createElement('script');
                e.async = true;
                e.src = 'https://connect.facebook.net/en_US/sdk.js';
                document.body.appendChild(e);
                window.fbAsyncInit = function () {
                    window.FB.init({
                        appId: '625178694184501',
                        autoLogAppEvents: true,
                        xfbml: true,
                        version: 'v8.0'
                    });
                    let objectArray = [];
                    window.FB.Event.subscribe('xfbml.ready', function (msg) {
                        if (msg.type === 'video') {
                            objectArray.push = new FBVideoTracker(msg.instance, msg.id, userObj );
                        }
                    });
                };
                resolve(instance);
            } catch (error) {
                reject(error);
            }
        })
    }
}


class FBVideoTracker extends BaseVideoTracker {

    constructor(instance, id, userObj) {
        super();
        instance.id = id;
        this.init(instance, "Facebook", userObj);
        let instanceObj = this;
        instance.subscribe('finishedPlaying', function (e) {
            instanceObj.onComplete();
        });
        instance.subscribe('startedPlaying', function (e) {
            instanceObj.onPlay();
        });
        instance.subscribe('paused', function (e) {
            instanceObj.onPause();
        });
        instance.subscribe('error', function (e) {
            instanceObj.onComplete();
        });

    }
}


