import {HttpHandler} from "sdk-js/common/http_partner";

const paths = require('path.json');

class RestEndPoint {

    constructor(endPointName, id, config, httpHeaders) {
        const instance = this;
        if (config === undefined) {
            throw new Error("Invalid config for " + endPointName);
        }
        instance.paths = paths;
        instance.mHttpHeaders = httpHeaders;
        instance.endPointName = endPointName;
        instance.config = config.endPoints[endPointName];
        instance.allConfig = config;
        instance.id = id;
        instance.currentOptions = instance.config.options !== undefined ? JSON.parse(JSON.stringify(instance.config.options)) : [];
        instance.arguments = null;
    };

    getPath = () => {
        return this.paths
    };

    setId = (id)  => {
        this.id = id;
    };

    getId = () => {
        return this.id
    };

    setHttpHeaders = (httpHeaders) => {
        this.mHttpHeaders = httpHeaders;
    };


    getHttpHeaders = () => {
        return this.mHttpHeaders;
    };

    updateOptions = (attribute, options) => {
        const instance = this;
        let childIndex = instance.config.children.indexOf(attribute);
        if (childIndex < 0) {
            return;
        }
        instance.currentOptions[childIndex] = Object.assign(options);
    };

    updateArguments = (obj) => {
        if(obj && Object.keys(obj).length){
            this.arguments = obj;
        }else{
            this.arguments = null;
        }
    };

    getArgumentsString(obj){
        if(obj && Object.keys(obj).length){
            return Object.entries(obj).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
        }else {
            return "";
        }
    }


    fetchData (attribute, optionalId, version) {
        const instance = this;
        let options = null;
        let url = null;
        if(version !== null && version !== undefined){
            url = paths[instance.allConfig.root] + `/api/${version}/ecom-item/`;
        }else{
            url = paths[instance.allConfig.root] + instance.config.endPoint;
        }
        if (instance.id !== 0 && instance.id !== undefined) {
            url += instance.id;
        }

        if (attribute !== undefined && attribute !== null) {
            let childIndex = instance.config.children.indexOf(attribute);
            if (childIndex < 0) {
                throw new Error("Invalid attribute " + attribute);
            }
            url += "/" + instance.config.children[childIndex];
            options = instance.currentOptions !== undefined && instance.currentOptions[childIndex] !== undefined ?
                instance.currentOptions[childIndex] : null;
        }

        if (optionalId !== undefined) {
            url += "/" + optionalId;
        }

        if(instance.arguments){
            url+="?"+instance.getArgumentsString(instance.arguments);
        }


        if (options !== undefined && options !== null) {
            url += instance.arguments ? '&' : '?';
            url += "options=" + JSON.stringify(options);
            instance.currentOptions = JSON.parse(JSON.stringify(instance.config.options));
        }

       return url;

    };

    getDataWithMeta = (attribute, optionalId, version = null) => {
        let instance = this;
        let url = instance.fetchData(attribute, optionalId, version);
        let httpHandler = new HttpHandler();
        httpHandler.addHeaders(instance.mHttpHeaders);
        return httpHandler.httpGet(url).then(
            response => {
                let rVal = {};

                if(response.data && response.data.data) {
                    rVal['data'] = response.data.data;
                }

                if(response.data && response.data.meta) {
                    rVal['meta'] = response.data.meta;
                }
                return rVal;
            }
        );
    };


    getData = (attribute, optionalId) => {
        return this.getDataWithMeta(attribute, optionalId).then(
            response => response.data
        )
    };


    delete = (attribute, deleteId) => {
        const instance = this;
        return new Promise(function (resolve, reject) {
            do {
                let childIndex = instance.config.children.indexOf(attribute);
                if (childIndex < 0) {
                    reject(new Error("Invalid attribute " + attribute));
                    break;
                }

                let url = paths[instance.allConfig.root] + instance.config.endPoint;

                if (instance.id !== undefined && instance.id) {
                    url += instance.id + "/";
                }

                url += instance.config.children[childIndex];

                let httpHandler = new HttpHandler();
                httpHandler.addHeaders(instance.mHttpHeaders);
                if (deleteId !== undefined) {
                    url += "/" + deleteId;
                }

                if(instance.arguments){
                    url+="?"+instance.getArgumentsString(instance.arguments);
                }

                httpHandler.httpDelete(url).then(
                    response => {
                        if (response.data.data !== undefined) {
                            resolve(response.data.data);
                        } else {
                            resolve(response.data);
                        }
                    },
                    error => reject(error)
                );
            } while (false);
        });
    };


    postData = (attribute, formData, editId, config,version) => {
        const instance = this;
        return new Promise(function (resolve, reject) {
            do {
                let childIndex = instance.config.children.indexOf(attribute);
                if (childIndex < 0) {
                    reject(new Error("Invalid attribute " + attribute));
                    break;
                }
                let url = null;
                if(version !== null && version !== undefined){
                    url = paths[instance.allConfig.root] + `/api/${version}/ecom-item/`;
                }else{
                    url = paths[instance.allConfig.root] + instance.config.endPoint;
                }
                if (instance.id !== undefined && instance.id) {
                    url += instance.id + "/";
                }

                url += instance.config.children[childIndex];

                let httpHandler = new HttpHandler();
                httpHandler.addHeaders(instance.mHttpHeaders);
                if (editId !== undefined && editId !== null && editId !== '') {
                    url += "/" + editId + "/update";
                }

                if (attribute === "" && instance.id !== undefined && instance.id) {
                    url += "/update";
                }

                httpHandler.httpPost(url, formData , config).then(
                    response => {
                        if (response.data.data !== undefined) {
                            resolve(response.data.data);
                        } else {
                            resolve(response.data);
                        }
                    },
                    error => reject(error)
                );
            } while (false);
        });
    };

    saveMapping = (endPointName, endPointId, mappedEndPointName, mappedItemIds) => {

        const instance = this;
        let restEndPoint = new RestEndPoint(endPointName, endPointId, instance.allConfig, instance.mHttpHeaders);
        let formData = new FormData();
        let idName = mappedEndPointName;
        idName = idName.substring(0, idName.length - 1);
        idName += "_ids";
        formData.append(idName, mappedItemIds);
        return restEndPoint.postData(mappedEndPointName, formData);
    }

    remindMe =(formData)=>{
        const instance = this;
        return new Promise(function(resolve,reject){
            do {
                let url =
                    paths[instance.allConfig.root] + instance.config.endPoint;

                if (instance.id !== undefined && instance.id) {
                    url += instance.id + "/";
                }
                url += "/subscribe";

                let httpHandler = new HttpHandler();
                httpHandler.addHeaders(instance.mHttpHeaders);

                httpHandler.httpPost(url, formData).then(
                    response => {
                        if (response.data.data !== undefined) {
                            resolve(response.data.data);
                        } else {
                            resolve(response.data);
                        }
                    },
                    error => reject(error)
                );
            } while (false);
        })
    }
}

export default RestEndPoint;
