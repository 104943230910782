
import {addTrackingParams} from "./common";
import axios from "axios";

export function HttpHandler(eventManager) {

    let querystring = require('querystring');

    let mConfig = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
    };

    this.addHeader  = function(key, value) {
        mConfig[key] = value;
    };

    this.addHeaders = function (configHeader) {
        mConfig = Object.assign(mConfig, configHeader);
    };

    this.httpGet = function (url) {
        url = addTrackingParams(url);
        return axios.get(url, {headers: mConfig});
    };


    this.httpPut = function (url, data, callback) {
        data = querystring.stringify(data);
        url = addTrackingParams(url);
        return axios.put(url, data, {headers: mConfig});
    };


    this.httpPost = function (url, data) {
        url = addTrackingParams(url);
        data = mConfig['Content-Type']==="application/x-www-form-urlencoded" ? querystring.stringify(data):data;
        return axios.post(url, data, {headers: mConfig});
    };

    this.httpPostFile = function (url, data, callback) {

        url = addTrackingParams(url);
        return axios.post(url, data, {headers: mConfig});
    };

}
